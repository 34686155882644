export const Notes = `Mdp messagerie : nom du chien + mon année naiss

Idée cadeau maman : appareil photo

Réserver char à voile pour cet été 

Trucs à faire : 
 - Faire le DM de maths 
 - Répondre à Mme Kolpberg 
 - Ne pas oublier le devoir de chimie !!!!

Délire Alfredo : RJYJTWF

Soirée chez Al demain soir !

Appeler la police ?`