export const Conversations = [
    {
        name: 'Alfredo',
        picture: require('../img/users/alfredo.png'),
        password: 'METEORA',
        messages: [
            {
                content: 'Salut ! A quelle heure la soirée ?',
                isSender: true
            },
            {
                content: 'tu viens quand tu veux',
                isSender: false
            },
            {
                content: 'Ok je viens pour 20h30',
                isSender: true
            },
            {
                content: 'Ramene des trucs stp, y aura du monde ca va être chaud si je suis le seul a avoir prévu des trucs',
                isSender: false
            },
            {
                content: 'Ok, tenerve pas Al, c’était prévu',
                isSender: true
            },
            {
                content: 'Ouais mais c’est chiant, personne ramene jamais rien et après c’est la merde',
                isSender: false
            },
            {
                content: 'Ok ok a tte',
                isSender: true
            },
            {
                content: 'Ouais c’est ca',
                isSender: false
            },
            {
                content: 'T’es partie tot toi',
                isSender: false
            },
            {
                content: 'Ouai',
                isSender: true
            },
            {
                content: 'T’aurais pu aider a ranger …, a chaque fois c’est la même, vous vous barrez et je range seul',
                isSender: false
            },
            {
                content: 'Vous cassez les couilles a faire ce cou a chaque fois',
                isSender: false
            },
            {
                content: 'Aller salut Al t’es vraiment chiant comme mec',
                isSender: true
            },
            {
                content: 'T’es sérieux d’avoir mis un code pour te parler ?',
                isSender: true
            },
            {
                content: 'Bah je croyait que j’étais chiant pourquoi tu me parles ?',
                isSender: false
            },
            {
                content: 'Tu t’es barré ou ?',
                isSender: false
            },
            {
                content: '[numero-rue.jpg]',
                isSender: true
            },
            {
                content: 'Ah ok t’es allée loin mdrr',
                isSender: false
            },
            {
                content: 'Ca rappelle les souvenirs de vacances',
                isSender: false
            },
            {
                content: 'Arrete je flippe',
                isSender: true
            },
            {
                content: 'Ouais tu m’etonnes, on va le retrouver ce mec et lui casser les tibias',
                isSender: false
            }
        ]
    },
    {
        name: 'Alice',
        picture: require('../img/users/alice.png'),
        password: '',
        messages: [
            {
                content: 'Salut salut Lala ! hâte de te voir à la soirée ce soir',
                isSender: false
            },
            {
                content: 'Oh tu viens toi aussi ?!',
                isSender: true
            },
            {
                content: 'Bien sur, j’allais pas manquer ca ! je veux voir la suite des aventures de Lana et Valentin au premier rang ma chère',
                isSender: false
            },
            {
                content: 'ahaha, il va rien se passer, c’est juste un pote pour moi',
                isSender: true
            },
            {
                content: 'c’est ca .. comme si j’allais te croire mdrr',
                isSender: false
            },
            {
                content: 'rooh tu vas pas t’y mettre toi aussi',
                isSender: true
            },
            {
                content: 'bref on se retrouve la bas',
                isSender: true
            },
            {
                content: 'ouais a tt',
                isSender: false
            },
            {
                content: 'Alors ca s’est passé comment je t’ai vu lui parler puis t’es partie et t’es pas revenue, tu vas bien ?',
                isSender: false
            },
            {
                content: 'Non, ca va pas trop…',
                isSender: true
            },
            {
                content: 'il s’est passé quoi ?',
                isSender: false
            },
            {
                content: 'je t’explique',
                isSender: true
            },
            {
                content: 'il m’a dit que je lui plaisait mais c’est pas réciproque.. alors je suis sortie faire un tour pour me changer les idées ',
                isSender: true
            },
            {
                content: 'Et dans le rue je vois qu’il y a une mec avec une capuche qui me suit',
                isSender: true
            },
            {
                content: 'alors j’ai vraiment flippé et j’ai d’abord essayé de le semer en tournant dans le quartier',
                isSender: true
            },
            {
                content: 'mais rien a faire il me suivait toujours',
                isSender: true
            },
            {
                content: 'alors je suis rentrée chez moi en courant',
                isSender: true
            },
            {
                content: 'j’ai vraiment eu trop peur',
                isSender: true
            },
            {
                content: 'oh trop flippant',
                isSender: false
            },
            {
                content: 'mais t’es sure qu’il te suivait vraiment ?',
                isSender: false
            },
            {
                content: 'oui je te dis !',
                isSender: true
            },
            {
                content: 'trop bizarre',
                isSender: false
            },
            {
                content: 'bon bonne nuit Lala,  t’inquiete c’est surement rien',
                isSender: false
            },
            {
                content: 'toi aussi Alice',
                isSender: true
            },
            {
                content: 'Salut ! on peut parler un peu ?',
                isSender: true
            },
            {
                content: 'oui bien sur qu’est ce qu’il y a ?',
                isSender: false
            },
            {
                content: 'j’ai l’impression d’avoir revu le mec qui me suivait dans la rue à travers la fenetre de chez moi',
                isSender: true
            },
            {
                content: 'ca me fait trop peur',
                isSender: true
            },
            {
                content: 'je sais pas quoi faire',
                isSender: true
            },
            {
                content: 'chaud.. appelle la police non ? c’est peut être un psychopathe',
                isSender: false
            },
            {
                content: 'je sais pas… je suis en stress',
                isSender: true
            },
            {
                content: 'en tout cas c’est étrange tout ca… tiens moi au courant',
                isSender: false
            },
            {
                content: 'Salut salut ! ca va ? t’as pas revu le gars depuis ?',
                isSender: false
            },
            {
                content: 'si… je l’ai revue dans la rue en sortant du bahut et en rentrant chez moi',
                isSender: true
            },
            {
                content: 'je commence a paniquer',
                isSender: true
            },
            {
                content: 't’es sure que tu veux pas appeler la police ?',
                isSender: false
            },
            {
                content: 'non je veux pas avoir d’ennuis',
                isSender: true
            },
            {
                content: 'ou alors tu te casses',
                isSender: false
            },
            {
                content: 'comment ca ?',
                isSender: true
            },
            {
                content: 'bah tu pars dans un lieu sur ou il pourra pas te retrouver',
                isSender: false
            },
            {
                content: 'bonne idée, je sais ou je vais aller !',
                isSender: true
            },
            {
                content: 'ok, tiens moi au courant',
                isSender: false
            },
            {
                content: 'oui !',
                isSender: true
            },
            {
                content: 'imagine le mec il rentre chez moi et fouille mes affaires pendant que suis pas la …',
                isSender: true
            },
            {
                content: 'mdrr tu t’es crue dans un film toi !',
                isSender: false
            },
            {
                content: 'ouais j’avoue',
                isSender: true
            },
            {
                content: 'bon je te laisse',
                isSender: true
            },
            {
                content: 'A+ Lala !',
                isSender: false
            }
        ]
    },
    {
        name: 'Christine',
        picture: require('../img/users/christine.png'),
        password: '',
        messages: [
            {
                content: 'Ca va je t’ai vu partir de la soirée qu’est ce qui se passe ?',
                isSender: false
            },
            {
                content: 'Rien',
                isSender: true
            },
            {
                content: 'Vasy dis',
                isSender: false
            },
            {
                content: 'C’est a cause de Valentin ?',
                isSender: false
            },
            {
                content: 'Non pas que',
                isSender: true
            },
            {
                content: 'Je lui ai mis un stop et je suis partie faire un tour dehors pour me changer les idées, et la je vois un mec chelou qui me suit, alors je suis rentrée vite fait chez moi, j’ai flippé',
                isSender: true
            },
            {
                content: 'C’était qui ce mec ? tu le connaissait',
                isSender: false
            },
            {
                content: 'Non',
                isSender: true
            },
            {
                content: 'Trop bizarre, c’était ptetre un clodo',
                isSender: false
            },
            {
                content: 'Non il avait pas la degaine d’un clodo',
                isSender: true
            },
            {
                content: 'Tinquiete ca dois être un mec bizarre c’est tout',
                isSender: false
            },
            {
                content: 'Oui c’est ce que je me dis',
                isSender: true
            },
            {
                content: 'Trop drôle la photo sur ton my space',
                isSender: false
            },
            {
                content: 'Ahaha',
                isSender: true
            },
            {
                content: 'T’écoute toujours ce groupe de musique ?',
                isSender: false
            },
            {
                content: 'Ouais c’est mon groupe préféré',
                isSender: true
            },
            {
                content: 'J’en suis sure que tu serais cap de le mettre en mdp mdrr',
                isSender: false
            },
            {
                content: 'Ahaha nan quand même pas ^^',
                isSender: true
            },
            {
                content: 'SALUT !! t’as fais ton devoir de chimie ???',
                isSender: true
            },
            {
                content: 'Non, je vais pas en cours demain',
                isSender: false
            },
            {
                content: 'Mais demande a Justine elle doit l’avoir fait elle',
                isSender: false
            },
            {
                content: 'D’accord merci tu gères',
                isSender: true
            },
            {
                content: 'Je sais :)',
                isSender: false
            },
            {
                content: 'Salut t’es passée ou ? ya Paul qui s’inquiete',
                isSender: false
            },
            {
                content: '?',
                isSender: false
            },
            {
                content: 'Ouais je suis restée chez moi aujourd’hui',
                isSender: true
            },
            {
                content: 'Pourquoi ?',
                isSender: false
            },
            {
                content: 'Rien, je suis un peu parano en ce moment',
                isSender: true
            },
            {
                content: 'Pourquoi ?',
                isSender: false
            },
            {
                content: '?',
                isSender: false
            },
            {
                content: 'T’es passée ou ?',
                isSender: false
            },
            {
                content: '?',
                isSender: false
            },
            {
                content: 'Ca va ?',
                isSender: false
            },
            {
                content: '?',
                isSender: false
            },
            {
                content: '??',
                isSender: false
            }
        ]
    },
    {
        name: 'Justine',
        picture: require('../img/users/justine.png'),
        password: '',
        messages: [
            {
                content: 'Hello, christine m’a dit que tu avais fait le devoir de physique ?',
                isSender: true
            },
            {
                content: 'Yep je l’ai fait',
                isSender: false
            },
            {
                content: 'Oooh tu pourrais me le filer vite fait ? j’ai complètement oublié de le faire, j’ai pas la tête aux devoirs en ce moment…',
                isSender: true
            },
            {
                content: 'Yes pas de soucis',
                isSender: false
            },
            {
                content: 'Oooh merci beaucoup tu me sauves la vie',
                isSender: true
            },
            {
                content: 'Je sais pas ce que j’aurais fait sans toi',
                isSender: true
            },
            {
                content: 'Je peux pas me permettre de me ramasser une autre sale note',
                isSender: true
            },
            {
                content: 'Ahaha t’inquiete je t’envoie ca par mail',
                isSender: false
            },
            {
                content: 'C’est quoi ton mail ?',
                isSender: false
            },
            {
                content: 'Merci !! Merci !!!!',
                isSender: true
            },
            {
                content: 'Mon  mail c’est lana.lariviere@hotmail.fr',
                isSender: true
            },
            {
                content: 'Super ! je t’envoi ca dans la soirée',
                isSender: false
            },
            {
                content: 'Merci !! tu gères',
                isSender: true
            },
            {
                content: 'Envoyé !',
                isSender: false
            },
            {
                content: 'Ah merci encore, je peux vraiment compter sur toi',
                isSender: true
            },
            {
                content: 'np, tu m’en dois une ;)',
                isSender: false
            }
        ]
    },
    {
        name: 'Paul',
        picture: require('../img/users/paul.png'),
        password: '',
        messages: [
            {
                content: 'Yo !',
                isSender: false
            },
            {
                content: 'Salut ! ça va mon gars ?',
                isSender: true
            },
            {
                content: 'Tu viens à la soirée d’alfredo ?',
                isSender: false
            },
            {
                content: 'Ouais bien sûr, je suis trop partante :D',
                isSender: true
            },
            {
                content: 'T’as vu en plus y aura Valentin',
                isSender: false
            },
            {
                content: 'Pourquoi tu dis ca ?',
                isSender: true
            },
            {
                content: 'Rien rien ;)',
                isSender: false
            },
            {
                content: 'Pas trop mal à la tête ?',
                isSender: true
            },
            {
                content: 'Nn trnquille',
                isSender: false
            },
            {
                content: 'T’es rentrée tot toi, il s’est passé quoi ?',
                isSender: false
            },
            {
                content: 'Rien j’ai pas trop envie d’en parler',
                isSender: true
            },
            {
                content: 'Ok',
                isSender: false
            },
            {
                content: 'YO YO !',
                isSender: false
            },
            {
                content: 'Hello ! :)',
                isSender: true
            },
            {
                content: 'T’as vu alfredo a encore un nouveau délire',
                isSender: false
            },
            {
                content: 'Oui j’ai vu,il est trop chiant avec son code a la con',
                isSender: true
            },
            {
                content: 'Ouais quel casse couille',
                isSender: false
            },
            {
                content: 'Au fait t’as fait les devoir de chimie ?',
                isSender: false
            },
            {
                content: 'Ptin c’était pour aujourd’hui ????',
                isSender: true
            },
            {
                content: 'Ouais, t’es dans la merde ma vieille xD',
                isSender: false
            },
            {
                content: 'Putain …',
                isSender: true
            },
            {
                content: 'Tu peux me l’envoyer ?',
                isSender: true
            },
            {
                content: '?',
                isSender: true
            },
            {
                content: '?',
                isSender: true
            },
            {
                content: '??',
                isSender: true
            },
            {
                content: 'Bon ok j’ai compris..',
                isSender: true
            },
            {
                content: 'Je vais demander a quelqu’un d’autre',
                isSender: true
            },
            {
                content: 'Desolé j’avais pas vu je prenais une douche',
                isSender: false
            },
            {
                content: 'Tinquiete j’ai réussie a le récupérer',
                isSender: true
            },
            {
                content: 'Ok cool',
                isSender: false
            },
            {
                content: 'Salut !',
                isSender: false
            },
            {
                content: '?',
                isSender: false
            },
            {
                content: 'On te voit plus en cours ca va ?',
                isSender: false
            },
            {
                content: '?',
                isSender: false
            },
            {
                content: 'ouais j’ai quelques problèmes perso en ce moment mais ca va',
                isSender: true
            },
            {
                content: 'Sérieux ??! il se passe quoi ?',
                isSender: false
            },
            {
                content: 'Rien ca va t’inquiete',
                isSender: true
            },
            {
                content: 'Au fait j’ai réussi a trouver la clé pour le code d’Alfredo, c’est Alice qui me l’a donné tu la veux ?',
                isSender: false
            },
            {
                content: 'Ouais vas-y',
                isSender: true
            },
            {
                content: 'A=5',
                isSender: false
            },
            {
                content: 'Merci, je dois y aller',
                isSender: true
            },
            {
                content: 'Dac, on te revoit bientôt j’espère',
                isSender: false
            },
            {
                content: 'Salut !',
                isSender: false
            },
            {
                content: '?',
                isSender: false
            },
            {
                content: 'Ca va ?',
                isSender: false
            },
            {
                content: 'J’ai recup tes devoirs',
                isSender: false
            },
            {
                content: 'YOOO !',
                isSender: false
            },
            {
                content: 'T’es ou ?',
                isSender: false
            },
            {
                content: 'On s’inquiète avec les potes',
                isSender: false
            }
        ]
    },
    {
        name: 'Valentin',
        picture: require('../img/users/valentin.png'),
        password: '',
        messages: [
            {
                content: 'Salut, tu viens demain chez Alfredo',
                isSender: false
            },
            {
                content: 'hey, ouais je viens',
                isSender: true
            },
            {
                content: 'cool !',
                isSender: false
            },
            {
                content: 'hâte de te voir là bas :)',
                isSender: false
            },
            {
                content: 'ouais, ca va etre marrant',
                isSender: true
            },
            {
                content: 'oui',
                isSender: false
            },
            {
                content: 'Salut ca va ? je suis désolé pour hier',
                isSender: false
            },
            {
                content: 'je savais pas que t’allais le prendre comme ca',
                isSender: false
            },
            {
                content: 'non t’inquiète pas c’est rien',
                isSender: true
            },
            {
                content: 'd’accord, on se voit en cours ?',
                isSender: false
            },
            {
                content: 'oui',
                isSender: true
            },
            {
                content: 'A demain :)',
                isSender: false
            },
            {
                content: 'a demain',
                isSender: true
            },
            {
                content: 'Ca fait plusieurs jours que tu viens plus, ca va ? t’es malade ?',
                isSender: false
            },
            {
                content: 'je peux prendre tes cours si tu veux et les rapporter chez toi',
                isSender: false
            },
            {
                content: 'comme ca on pourra passer un peu de temps ensemble',
                isSender: false
            },
            {
                content: 'Salut ca va ?',
                isSender: false
            },
            {
                content: '?',
                isSender: false
            }
        ]
    },
]